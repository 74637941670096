<template>
  <div class="modal fade" ref="mdlEdicionCampo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{modo == 'nuevo'?'Nuevo': 'Edición de'}} campo
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="p-xs">
            <input type="text" class="form-control" placeholder="Nombre del campo"
              maxlength="100"
              v-model="nombre_campo">
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-light btn-sm" data-bs-dismiss="modal"
            @click="cancelar()">
            Cancelar
          </button>
          <button class="btn btn-success btn-sm" @click="agregar()">
            <i class="fa fa-plus"></i>
            Agregar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlEdicionCampo',
  data() {
    return {
      modo: 'nuevo',
      nombre_campo: ''
    }
  },
  methods: {
    agregar() {
      this.$emit('campo:nuevo', this.nombre_campo)
      this.nombre_campo = ''
      var modal = Modal.getInstance(this.$refs.mdlEdicionCampo)
      modal.hide()
    },
    cancelar() {
      this.nombre_campo = ''
    },
    mostrar() {
      
      var modal = new Modal(this.$refs.mdlEdicionCampo)
      modal.show()
    },
  },
}
</script>

<style scoped>

</style>