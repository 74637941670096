import axios from 'axios'
import { APIINV as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/articulos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(articulo) {
    return apiClient.post(`/${articulo.id}`, articulo)
  },

  actualizarUnidadMedida(unidad){
    return apiClient.patch(`/unidades-medida/${unidad.id}`, unidad)
  },

  actualizarFotos(idArticulo, fotos) {
    let formData = new FormData(),
        headers = { headers: { 'Content-Type': 'multipart/form-data' } }

    // Obtención del indice de la foto principal
    let indiceFotoPrincipal = fotos.findIndex( f => f.principal == true )

    fotos.forEach(function(ft,index){
      // Registro del ID de la foto
      if (ft.id != undefined)
        formData.append("ids_fotos[]", ft.id)
      
      // Se registran las nuevas fotos a guardar en el servidor
      if (ft.file != null && ft.file != undefined) 
        formData.append('fotos[]', ft.file)
    })
    
    // Carga del indice de la foto principal
    formData.append('indice_foto_principal', indiceFotoPrincipal) 

    return apiClient.post(`/${idArticulo}/fotos`, formData, headers)
  },

  articulosJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/articulos.json`, { params: params })
  },

  articuloJSON(idArticulo,params) {
    params = params || {}
    return apiClient.get(`${idArticulo}.json`,{ params: params })
  },

  cantidad() {
    return apiClient.get(`cantidad`)
  },

  duplicar(idArticulo){
    return apiClient.post(`${idArticulo}/duplicar`)
  },

  eliminar(idArticulo) {
    return apiClient.delete(`/${idArticulo}`)
  },

  eliminarUnidadMedida(idUnidad, params) {
    params = params || {}
    return apiClient.delete(`/unidades-medida/${idUnidad}`, {params: params })
  },

  guardar(articulo) {
    return apiClient.post('', articulo)
  },

  guardarUnidadMedida(unidad) {
    return apiClient.post('/unidades-medida', unidad)
  },

  fotosJSON(idArticulo) {
    return apiClient.get(`${idArticulo}/fotos.json`)
  },

  unidadesMedidaJSON(){
    return apiClient.get('/unidades-medida.json')
  },

  unidadMedidaArticuloJSON(idArticulo, params) {
    params = params || {}
    return apiClient.get(`${idArticulo}/unidad-medida.json`, {params: params})
  },

  unidadesMedidaBaseJSON(){
    return apiClient.get('/unidades-medida-base.json')
  }
}