<template>
  <Layout :tituloPagina="`Inventario | Articulos | ${modo == 'nuevo' ? 'Nuevo':'Edición'}`">
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo' ? 'Nuevo':'Edición de'}} artículo
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="divFotoArticulo">
                  <img
                    :src="foto == null ? inventarioIcon : foto"
                    alt="Foto del articulos"
                    class="img-thumbnail"
                  />
                  <button
                    class="btnEliminarPrincipal btn btn-danger btn-sm btn-rounded"
                    v-show="foto != null"
                    @click="eliminarFotoPrincipal()"
                  >
                    <i class="mdi mdi-close-thick"></i>
                  </button>
                  <div class="d-grid gap-2">
                    <button
                      class="btn btn-sm btn-light btn-block"
                      v-show="foto == null"
                      @click="seleccionarFoto()"
                    >
                      <i class="mdi mdi-upload"></i>
                      Seleccionar
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-9 col-sm-6 col-xs-12">
                <div class="row">
                  <div class="col-md-12">
                    <label>Nombre</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="articulo.nombre"
                      ref="nombre"
                      name="nombre"
                      placeholder="Nombre del artículo"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <label>Descripción</label>
                    <textarea
                      placeholder="Descripción del artículo"
                      class="form-control"
                      v-model="articulo.descripcion"
                      ref="descripcion"
                      rows="5"
                      maxlength="64141"
                      name="descripcion"
                    ></textarea>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <label>División</label>
                    <div class="input-group">
                      <select
                        class="form-select"
                        v-model="articulo.id_familia"
                        name="division"
                        ref="division"
                        @change="actualizarFamiliaSeleccionada()"
                      >
                        <option :value="null">seleccionar</option>
                        <option v-for="familia in familias" :key="familia.id" :value="familia.id">{{familia.division}}</option>
                      </select>
                      <router-link :to="{name: 'nuevaFamiliaArticulos'}" class="btn btn-success">
                        <i class="fa fa-plus"></i>
                      </router-link>
                    </div>
                  </div>
                  
                  <div class="col-md-6">
                    <label>Familia</label>
                    <input
                      type="text"
                      class="form-control"
                      disabled readonly
                      v-model="familia.familia"
                      placeholder="Familia del artículo"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>Marca</label>
                <input
                  type="text" class="form-control"
                  v-model="articulo.marca" maxlength="50"
                  placeholder="Marca del artículo"
                />
              </div>
              <div class="col-md-6">
                <label>Modelo</label>
                <input
                  type="text" class="form-control"
                  v-model="articulo.modelo" maxlength="50"
                  placeholder="Modelo del artículo"
                />
              </div>
            </div>
            
            <hr>
            <div>
              <div>
                <label>Campos personalizados</label> &nbsp; &nbsp;
                <button class="btn btn-success btn-sm"
                  @click="mostrarMdlEdicionCampo('nuevo')">
                  <i class="mdi mdi-plus"></i>
                  Nuevo
                </button>
              </div>
              <br>

              <div class="input-group" v-for="(campo, index) in articulo.campos" :key="`campo-${index}`">
                <span class="input-group-text">{{campo.nombre}}</span>
                <input
                  type="text" class="form-control" 
                  placeholder="Valor del campo personalizado"
                  v-model="campo.valor" maxlength="250"
                />
                <button class="btn btn-outline-danger" @click="eliminarCampoPersonalizado(index)">
                  <i class="mdi mdi-trash-can-outline"></i>
                </button>
              </div>
            </div>
            
            <hr style="margin-top: 1rem; margin-bottom: 1rem;">

            <div class="row">
              <div class="col-md-7">
                <label>Unidad de medida</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    :value="articulo.unidad_medida.nombre"
                    placeholder="Nombre"
                    disabled
                    readonly
                    maxlength="250"
                  />
                  <input
                    type="text"
                    class="form-control"
                    :value="articulo.unidad_medida.abreviatura"
                    disabled
                    readonly
                    placeholder="Abreviatura"
                    maxlength="250"
                  />
                  <button class="btn btn-success"
                    @click="mostrarMdlUnidadesMedida()"
                    ref="btnMostrarMdlUnidadesMedida">
                    <i
                      class="mdi" 
                      :class="articulo.id_unidad_medida == null ? 'mdi-plus-thick' : 'mdi-refresh'"
                    ></i>
                    {{articulo.id_unidad_medida == null ? 'Seleccionar' : 'Cambiar' }}
                  </button>
                </div>
              </div>
              
              <div class="col-md-3" v-if="articulo.id_unidad_medida != null">
                <label>Stock mínimo</label>
                <div class="input-group">
                  <input
                    type="number"
                    placeholder="0"
                    v-model="articulo.stock_minimo"
                    class="form-control"
                  />
                  <span class="input-group-text">
                    {{articulo.unidad_medida.abreviatura}}
                  </span>  
                </div>
              </div>
            </div>
            
            <!-- División de equivalencias -->
            <!-- <div>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <strong>Equivalencias del artículo</strong>&nbsp;
                  <button class="btn btn-success btn-sm"
                    @click="mostrarMdlEquivalencia()"
                    :disabled="modo=='nuevo'"
                    :title="modo=='nuevo'?'Es necesario guardar para poder agregar una equivalencia':'Permite registrar nuevas equivalencias de artículos'">
                    <i class="fa fa-plus"></i>
                    Agregar equivalencia
                  </button>

                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th style="width: 75px">Cantidad</th>
                          <th style="width: 100px">Unidad de medida</th>
                          <th style="min-width: 200px">Artículo</th>
                          <th style="width: 100px">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(relacion, index) in articulo.relaciones_articulos">
                          <tr v-if="true" :key="`rel-${index}`">
                            <td class="text-right">{{relacion.cantidad}}</td>
                            <td>{{relacion.abreviatura_unidad_medida}}</td>
                            <td>{{relacion.nombre_articulo}}</td>
                            <td>
                              <button class="btn btn-danger btn-outline btn-xs"
                                @click="eliminarEquivalencia(index)">
                                <i class="fa fa-trash"></i>
                                Eliminar
                              </button>
                            </td>
                          </tr>
                          <tr v-if="true" :key="`equivalencias-${index}`">
                            <td></td>
                            <td colspan="3">
                              <strong>Equivale a:</strong> <br><br>
                              <table style="width: 100%">
                                <thead>
                                  <tr>
                                    <th style="width: 65px; vertical-align: top;">Cantidad</th>
                                    <th style="width: 100px; vertical-align: top;">Unidad de medida</th>
                                    <th style="vertical-align: top;">Artículo</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(relacion2, index) in relacion.relaciones_articulos"
                                    :key="`rel2-${index}`">
                                    <td class="text-right" style="padding-right: 5px">{{relacion2.cantidad}}</td>
                                    <td>{{relacion2.abreviatura_unidad_medida}}</td>
                                    <td>{{relacion2.nombre_articulo}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Fin de división de equivalencias -->
            <br>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Galeria artículo
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <a
              href="#"
              @click="agregarFoto()"
              class="btn btn-light bg-soft-dark btn-sm mb-2"
              onclick="return false;"
            >
              <i class="mdi mdi-upload"></i>
              Foto(s)
            </a>
            <br>
            <template v-for="(foto, index) in fotosArticulo">
              <div v-if="true" :key="`foto-${index}`" class="marcoFoto">
                <button
                  class="btnEliminarFoto btn btn-danger btn-sm btn-rounded"
                  title="Borrar foto"
                  @click="eliminarFoto(index)"
                >
                  <i class="mdi mdi-close-thick"></i>
                </button>

                <button
                  class="btnPrincipal btn btn-sm btn-rounded"
                  title="Foto principal"
                  :class="{
                    'btn-light': !foto.principal,
                    'btn-success': foto.principal
                  }"
                  @click="ponerPrincipal(foto)"
                >
                  <i
                    class="bx bx-circle"
                    v-show="!foto.principal"
                  ></i>
                  <i
                    class="bx bx-check-circle"
                    v-show="foto.principal"
                  ></i>
                </button>
                <img
                  v-if="foto.id != null && foto.id != undefined"
                  :src="`${API2}/imagenes/${foto.id}?tipo_foto=128x128&ruta=productos&_tk=${tk}`"
                  style="width:100px; height:100px;" @click="() => showCaptiomImg(index)"
                  class="galeria-imagen"
                />
                <img
                  v-if="(foto.id == null || foto.id == undefined) && foto.src != ''"
                  :src="foto.src" style="width:100px; height:100px;"
                  class="galeria-imagen"
                  @click="() => showCaptiomImg(index)"
                />
              </div>
            </template>

            <vue-easy-lightbox
              :visible="visibleCaption"
              :imgs="fotosArticulo"
              :index="index"
              @hide="handleCaptionHide"
            ></vue-easy-lightbox>
          </div>
        </div>
      </div>
    </div>
    <div class="text-right">
      <div class="btn-group">
        <button @click="cerrar()" class="btn btn-secondary">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="guardar()"
          v-if="modo == 'nuevo'"
          :disabled="bandera_spinner"
        >
          <i 
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Guardar
        </button>
        <button
          type="button"
          class="btn btn-success"
          @click="actualizar()"
          v-if="modo == 'edicion'"
          :disabled="bandera_spinner"
        >
          <i 
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>

    <MdlUnidadesMedida ref="mdlUnidadesMedida" 
      v-on:unidad-medida:seleccionada="agregarUnidadMedida($event)"
    />

    <MdlEdicionCampo ref="mdlEdicionCampo"
      v-on:campo:nuevo="agregarNuevoCampo($event)"/>

    <!-- <MdlEquivalencia ref="mdlEquivalencia" :articulo="articulo"
      v-on:equivalencia:lista="agregarEquivalencia($event)"/> -->
    <br>
  </Layout>
</template>

<script>
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import MdlUnidadesMedida from './MdlUnidadesMedida.vue'
import MdlEdicionCampo from './MdlEdicionCampo.vue'
// import MdlEquivalencia from './MdlEquivalencia.vue'
import VueEasyLightbox from "vue-easy-lightbox";
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import {APIINV as API} from '@/API.js'
import API2 from '@/API.js'
import Layout from "@/views/layouts/main"
export default {
  name: 'EdicionArticulo',
  components: {
    Layout,
    MdlUnidadesMedida,
    MdlEdicionCampo,
    // MdlEquivalencia,
    VueEasyLightbox
  },
  data() {
    return {
      modo: 'nuevo',
      API: API,
      API2: API2,
      tk: localStorage.getItem('argusblack.token'),
      articulo: {
        id: null,
        nombre: '',
        descripcion: '',
        id_familia: null,
        campos: [],
        stock_minimo: 0,
        marca: '',
        modelo:'',
        id_unidad_medida: null,
        unidad_medida: {
          id: null,
          nombre: '',
          abreviatura: '',
        },
        relaciones_articulos: []
      },
      fotosArticulo: [],
      familia: {},
      familias: [],
      camposDescripcion: [
        {
          nombre: 'marca',
          descripcion:'Marca',
          tipo:'text',
          unidades_metricas: []
        },
        {
          nombre: 'modelo',
          descripcion:'Modelo',
          tipo:'text',
          unidades_metricas: []
        }
      ],
      inventarioIcon: inventarioIcon,
      foto: null,
      bandera_spinner: false,
      index: 0, // default: 0
      visibleCaption: false,
    }
  },
  computed: {
    idArticulo() { return this.$route.params.id }
  },
  watch: {
    idArticulo(newVal, oldVal) {
      if(newVal == oldVal) return
      if(newVal == null) return

      this.cargarArticulo()
    }
  },
  created: function() {
    var self = this

    // Determinación del modo
    if (this.$route.path.indexOf('articulos/nuevo') != -1) this.modo = 'nuevo'
    else this.modo = 'edicion'

    if (this.modo == 'edicion') {
      this.cargarArticulo()
      this.cargarFotosArticulo()
    }
    
    this.refrescarFamilia()

    // Proceso que se ejecutará cuando las fotos estén cargadas
    iu.bus.on('fotos-cargadas', function() {
      let foto = self.fotosArticulo.find(foto => {
        return foto.principal == true
      })
        
      if (foto == null) {
        self.foto = null
      } else {
        if(foto.id == null) self.foto = foto.src
        else self.foto = `${self.API2}/imagenes/${foto.id}?tipo_foto=256x256&ruta=productos&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${self.tk}`
      }
    })
  },
  methods: {
    showCaptiomImg(index) {
      this.index = index;
      this.visibleCaption = true;
    },
    handleCaptionHide() {
      this.visibleCaption = false;
    },
    actualizar() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) return

      let articulo = Object.assign({}, self.articulo)

      delete articulo.unidad_medida
      delete articulo.foto_principal
      
      // Actualización de los datos del artículo
      ArticuloSrv.actualizar(articulo).then(response => {
        self.cargarArticulo()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el artículo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })

      // Actualización de las fotos del artículo
      ArticuloSrv.actualizarFotos(self.articulo.id, self.fotosArticulo).then(response => {
        iu.msg.success('Articulo actualizado')
        self.cargarFotosArticulo()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar las fotos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    actualizarComoUnidadPrincipal(index) {
      let self = this,
        um = Object.assign({}, this.articulo.unidades_medida[index])

      setTimeout(function(){
        self.articulo.unidades_medida.splice(index, 1)
        self.articulo.unidades_medida.splice(0, 0, um)
        
        for(let i; i<self.articulo.unidades_medida.length; i++){
          self.$refs[`rump-${i}`][0].checked = false
          
        }
        self.$refs["rump-0"][0].checked = true
      }, 300)
    },
    actualizarFamiliaSeleccionada() {
      let self = this
      Object.assign(self.familia, self.familias.find(fm => fm.id == self.articulo.id_familia))

      if(self.articulo.id_familia == null) {
        Object.assign(self.familia, {
          id: null,
          familia: '',
          division: '',
          descripcion: ''
        })
      }
    },
    agregarEquivalencia(relacion) {
      let self = this
      
      self.articulo.relaciones_articulos.push(relacion)
    },
    // agregarEquivalencia(equivalencia) {
    //   let index = this.articulo.equivalencias.findIndex(function(equiv){
    //     return (
    //         equiv.id_artequiv == equivalencia.id_artequiv && 
    //         equiv.id_unimedartequiv == equivalencia.id_unimedartequiv
    //       )
    //   })

    //   if(index >= 0) {
    //     iu.msg.warning("La equivalencia ya existe")
    //     return
    //   }
      
    //   this.articulo.equivalencias.push(equivalencia)
    // },
    agregarNuevoCampo(nombre) {
      this.articulo.campos.push({nombre: nombre, valor: ''})
    },
    agregarUnidadMedida(unidad) {
      var self = this

      self.articulo.unidad_medida = unidad
      self.articulo.id_unidad_medida = unidad.id
    },
    atras: function() {
      this.$router.go(-1)
    },
    cargarArticulo(id_articulo = null) {
      let self = this

      let idArticulo = id_articulo == null ? self.$route.params.id : id_articulo
      let params = {
        con_unidad_medida: true, 
        con_relaciones_articulos: true, 
        con_familia: true,
        con_stock_minimo: true,
        con_campos: true
      }

      ArticuloSrv.articuloJSON(idArticulo, params).then(response => {
        let tmp = Object.assign({}, response.data)

        delete tmp.familia

        Object.assign(self.articulo, tmp)
        Object.assign(self.familia, response.data.familia)

        self.cargarFotosArticulo(idArticulo)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el artículo '+idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarFotosArticulo: function(id_articulo = null) {
      var self = this
      let idArticulo = id_articulo == null ? self.$route.params.id : id_articulo
      
      ArticuloSrv.fotosJSON(idArticulo).then(response => {
        let fotos = response.data
        self.fotosArticulo = []
        fotos.forEach(ft => {
          let newFoto = Object.assign(ft, {
            src: `${self.API2}/imagenes/${ft.id}?ruta=productos&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${self.tk}`
          })
          self.fotosArticulo.push(newFoto)
        })
        iu.bus.emit('fotos-cargadas')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fotos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    eliminarCampoPersonalizado(index) {
      this.articulo.campos.splice(index, 1)
    },
    eliminarEquivalencia(index) {
      this.articulo.relaciones_articulos.splice(index, 1)
    },
    eliminarFotoPrincipal: function() {
      var self = this
      self.foto = null

      // Eliminación de la foto del fotosArticulo
      let indexFotoPrincipal = self.fotosArticulo.findIndex(f => f.principal == true)
      self.fotosArticulo.splice(indexFotoPrincipal, 1)

      // Asignación de la nueva foto principal
      if(self.fotosArticulo.length > 0){
        self.fotosArticulo[0].principal = true
        if(self.fotosArticulo[0].id == null) self.foto = self.fotosArticulo[0].src
        else self.foto = `${self.API2}/imagenes/${self.fotosArticulo[0].id}?tipo_foto=256x256&ruta=productos&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${self.tk}`
      }
    },
    mostrarMdlUnidadesMedida() {
      this.$refs.mdlUnidadesMedida.mostrar()
    },
    mostrarMdlEdicionCampo(modo) {
      this.$refs.mdlEdicionCampo.mostrar(modo)
    },
    mostrarMdlEquivalencia() {
      this.$refs.mdlEquivalencia.mostrar()
    },
    refrescarFamilia: function() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv: true,
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        self.familias = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cerrar: function() {
      this.atras()
    },
    articulosCampos: function() {
      this.articulo.campos = []
      let campos = this.familia.campos

      campos.forEach(campo => {
        let nombre = campo.nombre
        let camp = this.camposDescripcion.find(campo => campo.nombre == nombre)
        if(campo.visible == 1){
          let nuevoCampo = {
            nombre: camp.nombre,
            descripcion: camp.descripcion,
            tipo: camp.tipo,
            unidades_metricas: camp.unidades_metricas,
            unidad_metrica: null,
            valor: ''
          }
          this.articulo.campos.push(nuevoCampo)
        }
      })
    },
    articuloCamposDescripcion: function () {
      let campos = this.articulo.campos
      let campos_familia = this.familia.campos

      campos_familia.forEach(cmp_fam => {
        if(cmp_fam.visible){
          campos.find(campo => campo.nombre == cmp_fam.nombre) === undefined && campos.push({
            nombre: cmp_fam.nombre,
            unidad_metrica: null,
            valor: null
          })
        }
      })

      campos.forEach(campo => {
        let nombre = campo.nombre
        let descrip = this.camposDescripcion.find(campo => campo.nombre == nombre)
        Object.assign(campo,{
          descripcion : descrip.descripcion,
          tipo: descrip.tipo,
          unidades_metricas: descrip.unidades_metricas
        })
      })
    },
    guardar: function() {
      var self = this

      self.bandera_spinner = true
      
      if (!self.verificarDatosCorrectos()) return

      let articulo = Object.assign({}, self.articulo)

      delete articulo.unidad_medida
      delete articulo.foto_principal
      
      ArticuloSrv.guardar(articulo).then(response=>{
        // Actualización de las fotos del artículo
        var idArticulo = response.data
        ArticuloSrv.actualizarFotos(idArticulo, self.fotosArticulo).then(response => {}).catch(error => {
          let mensaje
          try {
            mensaje = error.response.data.message
          } catch (e) {
            mensaje = 'No se pudieron guardar las fotos'
          }
          iu.msg.error(mensaje)
          console.log(error)
        })

        iu.msg.success('Se guardó correctamente el artículo '+self.articulo.nombre)
        
        self.$router.replace({
          name: 'edicionArticulo',
          params: { id: idArticulo }
        })

        self.modo = 'edicion'
        self.articulo.id = idArticulo

        self.cargarArticulo(idArticulo)
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el artículo'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    seleccionarFoto: function() {
      let self = this
      
      var inpFoto = document.createElement("input")
      inpFoto.setAttribute('type', 'file')

      var reader = new FileReader()

      inpFoto.click()
      
      reader.onload = function(e) {
        self.foto = this.result
      }

      inpFoto.addEventListener('change', function() {
        var foto = {
          id: null,
          file: inpFoto.files[0],
          src:null,
          principal: true
        }
        
        self.fotosArticulo.push(foto)
        self.cargarSrcFoto(foto)
        reader.readAsDataURL(inpFoto.files[0])
      })
    },
    verificarDatosCorrectos(){
      var self = this

      if (self.articulo.id_familia == null) {
        iu.msg.warning('Es necesario seleccionar una división')
        self.$refs.division.focus()
        self.bandera_spinner = false
        return false
      }

      if (self.articulo.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre al artículo')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return false
      }

      if (self.articulo.descripcion == '') {
        iu.msg.warning('Es necesario ingresar una descripción al artículo')
        self.$refs.descripcion.focus()
        self.bandera_spinner = false
        return false
      }

      if(self.articulo.descripcion.length > 64141) {
        iu.msg.warning('El límite de 64,141 caracteres permitido para la descripción del articulo')
        self.$refs.descripcion.focus()
        self.bandera_spinner = false
        return
      }

      if(self.articulo.id_unidad_medida == null) {
        iu.msg.warning('Es necesario seleccionar una unidad de medida');
        self.$refs.btnMostrarMdlUnidadesMedida.click()
        self.bandera_spinner = false
        return false
      }

      return true
    },
    /**
     * Control de fotos de articulos
     */
    agregarFoto: function() {
      var self = this
      
      var input = document.createElement("input")
      input.setAttribute('type', 'file')
      input.setAttribute('multiple', '')

      input.click()
      input.addEventListener('change', function(e) {
        var inputFoto = e.target

        for(var i = 0; i < inputFoto.files.length; i++){
          let foto = {
            id: null,
            file: inputFoto.files[i],
            src: null,
            principal: false
          }

          var reader = new FileReader()

          reader.onload = function(e) {
            foto.src = e.target.result
            // Pone como principal a la primera foto agregada
            if (self.fotosArticulo.length == 0) foto.principal = true
            self.fotosArticulo.push(foto)
          }

          reader.readAsDataURL(foto.file)
        }
        iu.bus.emit('fotos-cargadas')
      })
    },
    cargarSrcFoto: function(foto) {
      var reader = new FileReader()

      reader.onload = function(e) {
        foto.src = e.target.result
      }

      reader.readAsDataURL(foto.file)
    },
    eliminarFoto: function(index) {
      var self = this
      self.fotosArticulo.splice(index, 1)

      // Detección de foto principal
      var fps = this.fotosArticulo.filter(function(f) {
        return f.principal == true
      })

      // Si no hubo alguna foto principal se asigna una por default
      if (fps.length == 0 && this.fotosArticulo.length > 0)
        this.fotosArticulo[0].principal = true

      this.$emit('fotos-cargadas')
    },
    ponerPrincipal: function(foto) {
      var self = this
      
      self.fotosArticulo.forEach(function(foto) {
        foto.principal = false
      })

      foto.principal = true

      this.$emit('fotos-cargadas')
    }
  }
}
</script>

<style scoped>
.divFotoArticulo {
  width:100%;
  height: 100%;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.divFotoArticulo img {
  width: 100%;
}
.btnEliminarPrincipal {
  position: absolute;
  right: 0px;
  top: 0px;
}
.btnPrincipal {
  position: absolute;
  right: 33px;
  top: 0px;
  margin: 0px -2px 5px 5px;
}
.btnEliminarFoto {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 0px 0px 5px 5px;
}
.marcoFoto {
  position: relative;
  display: inline-block;
  width:100px;
  height: 100px;
  margin: 5px 5px 5px 5px;
}
.galeria-imagen {
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>