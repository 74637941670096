import axios from 'axios'
import { APIINV as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/articulos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(familia) {
    return apiClient.patch(`/familias/${familia.id}`, familia)
  },

  eliminar(idFamilia) {
    return apiClient.delete(`/familias/${idFamilia}`)
  },

  familiasJSON(params) {
    params = params || {}
    return apiClient.get(`/familias.json`, { params: params })
  },

  familiaJSON(idFamilia) {
    return apiClient.get(`/familias/${idFamilia}.json`)
  },

  migrarArticulos(idFamilia,idDestino) {
    return apiClient.patch(`/familias/${idFamilia}/migrar-articulos`, {id_destino: idDestino})
  },

  guardar(familia) {
    return apiClient.post('/familias', familia)
  },

  cantidad() {
    return apiClient.get(`/familias/cantidad`)
  },

}